<template>
  <div class="container" :style="{backgroundImage: 'url(' + shareImage + ')'}">
    <div class="rank-box"><span class="rank" :data-rank="rank">{{ rank }}</span></div>
    <div class="hours-box"><span class="hours" :data-hours="hours">{{ hours }}</span></div>
    <div class="minutes-box"><span class="minutes" :data-minutes="minutes">{{ minutes }}</span></div>
    <div class="qrcode" :style="{backgroundImage: 'url(' + qrcode + ')'}"></div>
  </div>  
</template>

<script>
  import $fetch from '@/utils/fetch.js';
  export default {
    data() {
      return {
        auth: '',
        pointId: '',
        shareImage: '',
        rank: '',
        hours: '',
        minutes: '',
        filename: '',
        qrcode: '', 
      }
    },
    created() {
      let { auth, pointId, shareImage, rank, hours, minutes, filename } = this.$route.query;
      this.auth = auth;
      this.pointId = pointId;
      this.shareImage = decodeURIComponent(shareImage);
      this.rank = rank || '?';
      this.hours = hours;
      this.minutes = minutes;
      this.filename = filename;
      if(filename) this.handleShortenLink('filename', filename);
      else this.handleShortenLink('checkpoint', pointId);
    },
    methods: {
      /** 多参数缩短 */ 
      async handleShortenLink(k, v) {
        const { auth } = this;
        const { result }  = await $fetch.fetch('xx.cumen.equip.v1.EquipService/ShortenString',
          { action: 1, val: `?${ k }=${ v }`, useDb: true },
          { Authorization: auth }
        );
        this.getWechatCode(result);
      },
      /** 获取活动的小程序码 */
      getWechatCode(scene) {
        const _ = this;
        const pageName = _.filename ? 'promotional' : 'index';
        $fetch
          .post("GetWeixinQRCode", {
            page: `packages/scenic-area/challenge/pages/${pageName}/index`,
            scene,
            width: 80,
            is_hyaline: true,
          })
          .then((code) => {
            _.qrcode = "data:image/jpeg;base64," + code.image;
          });
      },
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$PuHuiH: AlibabaPuHuiTiH Alibaba-PuHuiTi-H PingFangSC-Medium;
$multipleCount: 1.2376;

.container {
  position: relative;
  width: px2rem(606 * $multipleCount);
  height: px2rem(948 * $multipleCount);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  /*> .rank {
    @include row(flex-start, flex-end);
    position: absolute;
    left: px2rem(22 * $multipleCount);
    bottom: px2rem(68 * $multipleCount);

    > .label {
      position: relative;
      color: #222;
      font-weight: bold;
      font-size: px2rem(32 * $multipleCount);
      line-height: px2rem(54 * $multipleCount);
      z-index: 1;

      &::after {
        content: attr(data-text);
        position: absolute;
        left: 0;
        top: 0;
        -webkit-text-stroke: px2rem(6 * $multipleCount) #93fa94;
        z-index: -1;
      }
    }

    > .value {
      position: relative;
      color: #222;
      font-weight: bold;
      font-size: px2rem(80 * $multipleCount);
      line-height: px2rem(80 * $multipleCount);
      margin: 0 px2rem(16 * $multipleCount);
      vertical-align: middle;
      z-index: 1;

      &::after {
        content: attr(data-text);
        position: absolute;
        left: 0;
        top: 0;
        -webkit-text-stroke: px2rem(8 * $multipleCount) #93fa94;
        z-index: -1;
      }
    }
  }*/

  > .rank-box {
    @include row(center);
    position: absolute;
    top: px2rem(56 * $multipleCount);
    left: px2rem(198 * $multipleCount);
    width: px2rem(146 * $multipleCount);
    height: px2rem(80 * $multipleCount);

    > .rank {
      position: relative;
      text-align: center;
      font-size: px2rem(80 * $multipleCount);
      line-height: px2rem(80 * $multipleCount);
      color: #222;
      font-family: BebasNeue-Regular;
      z-index: 1;

      &::after {
        content: attr(data-rank);
        position: absolute;
        left: 0;
        top: 0;
        -webkit-text-stroke: px2rem(8 * $multipleCount) #b2f36e;
        z-index: -1;
      }
    }
  }

  > .hours-box {
    @include row(center);
    position: absolute;
    bottom: px2rem(26 * $multipleCount);
    left: px2rem(76 * $multipleCount);
    width: px2rem(96 * $multipleCount);
    height: px2rem(80 * $multipleCount);

    > .hours {
      position: relative;
      text-align: center;
      font-size: px2rem(80 * $multipleCount);
      line-height: px2rem(80 * $multipleCount);
      color: #222;
      font-family: BebasNeue-Regular;
      z-index: 1;

      &::after {
        content: attr(data-hours);
        position: absolute;
        left: 0;
        top: 0;
        -webkit-text-stroke: px2rem(8 * $multipleCount) #b2f36e;
        z-index: -1;
      }
    }
  }

  > .minutes-box {
    @include row(center);
    position: absolute;
    bottom: px2rem(26 * $multipleCount);
    left: px2rem(234 * $multipleCount);
    width: px2rem(64 * $multipleCount);
    height: px2rem(80 * $multipleCount);

    > .minutes {
      position: relative;
      text-align: center;
      font-size: px2rem(80 * $multipleCount);
      line-height: px2rem(80 * $multipleCount);
      color: #222;
      font-family: BebasNeue-Regular;
      z-index: 1;

      &::after {
        content: attr(data-minutes);
        position: absolute;
        left: 0;
        top: 0;
        -webkit-text-stroke: px2rem(8 * $multipleCount) #b2f36e;
        z-index: -1;
      }
    }
  }

  .qrcode {
    position: absolute;
    right: px2rem(26 * $multipleCount);
    bottom: px2rem(24 * $multipleCount);
    width: px2rem(120 * $multipleCount);
    height: px2rem(120 * $multipleCount);
    background-repeat: no-repeat;
    background-position: center center; 
    background-size: 90% 90%;
    border-radius: px2rem(24 * $multipleCount);
    background-color: #fff;
  }
}
</style>
